// #region Imports
import { useTranslation } from "react-i18next";
import Icon from "../../atoms/Icon"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer
} from "recharts";
// #endregion

const Barcharts = ({ graphData, rawData, type, languageCode, suffix }) => {
  // #region Variables
  const { t } = useTranslation();

  const dataKeyTranslations = {
    LT: t("lt"),
    MT: t("mt"),
    // REFRIGERATION: t("refrigeration"),
    HVAC: t("hvac"),
    // CHEST_FREEZER: t("chest_freezer"),
    // AIR_CONDITIONING: t("air_conditioning"),
    LIGHTING: t("lighting"),
    PHOTOVOLTAIC: t("photovoltaic"),
    BAKERY: t("bakery"),
    // DELICACIES: t("delicacies"),
    HEAT_RECOVERY: t("heat_recovery"),
    HEAT_GENERATION: t("heat_generation"),
    // RESIDUAL_CURRENT: t("residual_current"),
    RESTAURANT: t("restaurant"),
    OTHER: t("residual") // THIS IS NOT COMMING FROM THE BACKEND!
  };

  const colorMap = {
    PHOTOVOLTAIC: "#4FC4F0",
    HEAT_RECOVERY: "#44D1A9",
    HEAT_GENERATION: " #255C26",
    LT: "#76B1D9",
    MT: "#105480",
    LIGHTING: "#6E0D0F",
    HVAC: "#BF7CB6",
    AIR_CONDITIONING: "#9B1985",
    CHEST_FREEZER: "#4025BB",
    BAKERY: "#BF7C7D",
    RESTAURANT: "#E99E59",
  };

//   const colorMap = {
//     PHOTOVOLTAIC: "#4FC4F0",
//     HEAT_RECOVERY: "#44D1A9",
//     HEAT_GENERATION: " #255C26",
//     LT: "#49BD45",
//     MT: "#7C55AF",
//     REFRIGERATION: "#2EC0E8",
//     LIGHTING: "#6E0D0F",
//     HVAC: "#BF7CB6",
//     AIR_CONDITIONING: "#9B1985",
//     CHEST_FREEZER: "#4025BB",
//     BAKERY: "#BF7C7D",
//     RESIDUAL_CURRENT: "#AF855D",
//     RESTAURANT: "#E99E59",
//     DELICACIES: "#D82727",
//     OTHER: "#787878"
//   };
  // #endregion

  // #region Functions


    // All this need to be done first!
    let tot_series = rawData.series.find(x => x.name === 'TOTAL')?.data; // TODO: Change to total
    let average = 0
    if(tot_series){
        graphData = graphData.map(element => {
          let tot = 0
          Object.keys(dataKeyTranslations).forEach(key => {
              tot = tot + (element[key] ?? 0)
          })
          element["OTHER"] =  ((tot_series[element["timeStamp"]] ?? 0)) - tot
          return element
        });
        average = parseInt(Object.values(tot_series).reduce((total, num) => total + num, 0) / Object.values(tot_series).length)
    }

    const availableKeys = Object.keys(dataKeyTranslations).filter((key) =>
        graphData.some((entry) => entry[key] !== undefined && entry[key] !== 0)
      );


    // Generate all warnings here?
    // and in the future redo how all this is done. so its states and works correct
    let warnings = []
    graphData.forEach(element => {
        if(element.TOTAL === 0 && !warnings.includes("zero_total")){
            warnings.push("zero_total")
        }
        if(element.OTHER < 0 && !warnings.includes("negative_residual")){
            warnings.push("negative_residual")
        }
    });

  // endRegion

  // region Graph
  const graph = () => {
    return (
      <ResponsiveContainer width="100%" height={350}>
        <BarChart
          data={graphData}
          margin={{ top: 0, right: 15, left: 7, bottom: 40 }}
          stackOffset="sign"
        >
          <XAxis dataKey="month" interval={0}
          />
          <YAxis tickFormatter={(t) => t.toLocaleString(languageCode)}  />
          {/* <Tooltip
            formatter={(value, name) => [
              value.toLocaleString(languageCode) + " kWh",
              dataKeyTranslations[name] || name
            ]}
          /> */}
          <Tooltip 
            content={({ payload }) => {
                if (!payload || payload.length === 0) return null;
                const data = payload[0].payload;
                const reversedPayload = [...payload].reverse();
                return (
                <div className="bg-white p-4 rounded-lg border border-gray-300 shadow-lg">
                    <strong>{data.name}</strong>
                    <br />
                    {reversedPayload.map((entry, index) => (
                    <div key={index} style={{ color: entry.color }}>
                        {dataKeyTranslations[entry.name]}: {entry.value.toLocaleString(languageCode) + " kWh"} 
                    </div>
                    ))}
                </div>
                );
            }}
        />
          <Legend formatter={(value) => dataKeyTranslations[value] || value} />
          <ReferenceLine y={0} stroke="#000" />

          {availableKeys.map((key) => {
            return (
              <Bar
                key={key}
                dataKey={key}
                stackId={"stack"}
                fill={colorMap[key]}
              />
            );
          })}
          {/* Have to comment away, this value is not correct! */}
          {average && (
            <ReferenceLine
              y={average}
              stroke="#000000"
            //   label={`${t("average")}: ${average.toLocaleString(
            //     languageCode
            //   )}`}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    );
  };

  // #region Return
  return (
    <section className="flex flex-col items-center p-4 space-y-4 bg-white shadow rounded-lg">
      <div className="ml-3 w-[96%]" data-html2canvas-ignore="true">
        <h5 className="text-categoryTitle font-yantramanavBold">
          <p>{t(type)}</p>
        </h5>
      </div>
      <div className="flex justify-end w-[96%] items-end my-1 mx-auto">
        {average ? (
          <p>
            <span className="font-yantramanavMedium">{t("average")}: </span>
            <span className="font-yantramanavLight">
              {average.toLocaleString(languageCode)} {t(suffix)}
            </span>
          </p>
        ) : null}
      </div>
      <div className="flex w-full justify-center">
        <span className="block transform -rotate-90 my-auto items-center w-1 pt-6 whitespace-nowrap">
          <span className="text-xs font-yantramanavMedium pr-2">
            <p>{t("electrical_energy_kwh_week")}</p>
          </span>
        </span>
        {graph()}
      </div>
      <div
        className="export-graph-row flex justify-end w-full"
        data-html2canvas-ignore="true"
      ></div>
      <div className="flex flex-col w-full">
        {
            warnings.map(warning => (
                <div className="p-4 bg-white shadow-md rounded-md flex flex-row px-2 my-2 border">
                    <div className="w-1/4 flex items-center justify-center">
                        {/* styles={"text-red-200"} dont work. needs to investigate why */}
                        <Icon size={40}>alert_error_circle</Icon>
                    </div>
                    <div className="flex flex-col w-full">
                        <p className="text-center font-bold">{t("analytics_warning_"+warning)}</p>
                        <p>{t("analytics_warning_"+warning+"_explanation")}</p>
                    </div>
                </div>
            ))
        }
      </div>
    </section>
  );
};
// #endregion

export default Barcharts;
