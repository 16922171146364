import React, { useState } from "react";
import useMount from "../../useMounts/UserPage";
import { useTranslation } from "react-i18next";
import { updateUser } from "../../api/api";
import Dropdown from "../atoms/Dropdown";
import Loadingsymbol from "../atoms/Loadingsymbol";
import Modal from "./Modal";
import Button from "./Button";
import Dropzone from "react-dropzone";
import UserProfileImage from "../atoms/UserProfileImage";

function UpdateUser({ isOpen, onClose, onUpdated }) {
  const {
    userId,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    language,
    setLanguage,
    profilePic,
    setProfilePic
  } = useMount();
  const [loading, setLoading] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState(null);
  const { t } = useTranslation();

  const updateUserInfo = async (e) => {
    e.preventDefault();
    const user = { first_name: firstName, last_name: lastName, language };

    if (!user) {
      return;
    } else {
      setLoading(true);
      await updateUser(user, acceptedFile, userId);
      setLoading(false);
      onClose();
      onUpdated();
    }
  };

  const handleImageDrop = async (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles[0]) {
      setAcceptedFile(acceptedFiles[0]);
      var reader = new FileReader();
      reader.onload = function (e) {
        setProfilePic(e.target.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    }
  };

  const inputDivStyles = "h-[55px] w-full flex flex-col gap-[2px]";

  const inputStyles =
    "h-4 bg-transparent font-yantramanavRegular text-textInput outline-none w-full text-ellipsis truncate border-b text-black border-b-darkGray focus:border-b-darkGray";

  const labelStyles =
    "text-black text-[14px] leading-[18.16px] tracking-[0.5px] font-yantramanavLight h-[19px]";

  return (
    <Modal isOpen={isOpen} modalTitle="edit_profile" onClose={onClose}>
      <div className="w-[222px] h-[337px] flex flex-col items-center gap-[16px]">
        <div className="w-[120px] h-[100px] flex flex-col items-center gap-1">
          <UserProfileImage
            firstName={firstName ?? ""}
            lastName={lastName ?? ""}
            pathImage={profilePic ?? ""}
          />
          <Dropzone acceptedFiles={["image/jpeg"]} onDrop={handleImageDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                {...getRootProps()}
                style={{
                  border: isDragActive ? "2px dashed gray" : null, // Dotted border when hovering with file
                  transition: "background-color 0.3s"
                }}
              >
                <Button color="transparent" text={t("edit_image")} />
              </div>
            )}
          </Dropzone>
        </div>
        <p className={labelStyles}>{t("version")} 2.2.0</p>

        <form className="w-[222px] h-[221px] gap-[24px]">
          <div className={inputDivStyles}>
            <label htmlFor="firstName" className={labelStyles}>
              {" "}
              {t("first_name")}{" "}
            </label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className={inputStyles}
              placeholder={t("first_name")}
            />
          </div>
          <div className={inputDivStyles}>
            <label htmlFor="lastName" className={labelStyles}>
              {" "}
              {t("last_name")}{" "}
            </label>
            <input
              id="lastName"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className={inputStyles}
              placeholder={t("last_name")}
            />
          </div>
          {language !== "" && (
            <div className={inputDivStyles}>
              <label htmlFor="language" className={labelStyles}>
                {" "}
                {t("language")}{" "}
              </label>
              <Dropdown
                options={["en", "de", "sv", "dev"]}
                initialValue={language}
                placeholder={t("language")}
                onChange={(e) => {
                  setLanguage(e);
                }}
              />
            </div>
          )}
          {loading ? (
            <div className="w-full flex justify-center">
              {" "}
              <Loadingsymbol />{" "}
            </div>
          ) : (
            <Button
              fill
              text="save_changes"
              enabled={true}
              onClick={(e) => updateUserInfo(e)}
            />
          )}
        </form>
      </div>
    </Modal>
  );
}

export default UpdateUser;
