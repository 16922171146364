import { useTranslation } from "react-i18next";
import Button from "../../components/molecules/Button";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Loadingsymbol from "../../components/atoms/Loadingsymbol";
import Barcharts from "../../components/molecules/charts/Barcharts";
import LineCharts from "../../components/molecules/charts/Linechart";
import Scatteredchart from "../../components/molecules/charts/Scatteredchart";
import { fetchData } from "../../api/api";
import { useEffect, useState } from "react";
import { getAsyncStorage } from "../../utils/AsyncStorage";
import PieCharts from "../../components/molecules/charts/PieChart";
import Tab from "../../components/molecules/Tab";

function addUnixDays(startTime, days) {
  return startTime + Math.floor(days * 24 * 60 * 60 * 1000);
}

function getISOWeekNumber(date) {
    const tempDate = new Date(date);
    tempDate.setHours(0, 0, 0, 0);
    tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7)); // Move to Thursday
    const firstThursday = new Date(tempDate.getFullYear(), 0, 4);
    const weekNumber = Math.ceil(((tempDate - firstThursday) / 86400000 + firstThursday.getDay() + 1) / 7);
    return weekNumber;
  }

export default function Analytics() {
  const { t } = useTranslation();
  const { marketId } = useParams();
  const [tab, setTab] = useState({
    weekly_energy: true,
    category_share: false
  });
  const [languageCode, setLanguageCode] = useState("de-DE");

  const languageCodes = {
    de: "de-DE",
    en: "en-GB",
    se: "sv-SE"
  };

  useEffect(() => {
    getAsyncStorage("user").then((res) => {
      setLanguageCode(languageCodes[res.language]);
    });
  });

  const res = useQuery({
    queryKey: [
      marketId,
      "data",
      "analytics",
      { url: `energy_52_weeks?market_id=${marketId}` }
    ],
    queryFn: fetchData
  });

  const pieChart = useQuery({
    queryKey: [
      marketId,
      "data",
      "analytics",
      { url: `energy_category_share?market_id=${marketId}` }
    ],
    queryFn: fetchData
  });

  if (res.isPending || pieChart.isPending) {
    return (
      <div className="h-full">
        <div className="flex h-[46px] border-r border-b px-8 w-full" />
        <div className="flex flex-col items-center mt-8">
          <Loadingsymbol />
          <p className="text-body font-yantramanaRegular text-primary">
            {t("fetching_data")}...
          </p>
        </div>
      </div>
    );
  }

  let graphData = [];
  if (!res.isError) {
    res?.data?.series?.forEach((resDataItem) => {
      if (resDataItem.name === "total" || resDataItem.name === "refrigeration")
        return;
      const sortedData = Object.entries(resDataItem.data).sort(([a], [b]) => Number(a) - Number(b));
      sortedData.forEach(
        ([timeStamp, dataPoint], idx) => {
          if (graphData[idx] === undefined) {
            graphData.push({});
          }

          const startDate = new Date(timeStamp);
          let endDate = new Date(timeStamp);
          endDate.setDate(endDate.getDate() + 7);
          // const endDate = new Date(addUnixDays(timeStamp, 7));
          let startDay = startDate.getDate().toString().padStart(2, "0");
          let endDay = endDate.getDate().toString().padStart(2, "0");
          const name = `${startDay} ${startDate.toLocaleString(undefined, {
            month: "short",
            year: "2-digit"
          })} - ${endDay} ${endDate.toLocaleString(undefined, {
            month: "short",
            year: "2-digit"
          })}`;
        // TODO: What to choose here.
        // Play with the different versions and the "interval" row 119 in BarChart
        // const week = "week " + getISOWeekNumber(new Date(timeStamp))
        //   const week = (idx - 51) === 0 ? t("now") : (idx - 51)
        // const week = startDate.toLocaleDateString(undefined, { month: 'long' });
        // const week = idx === 51 ? t("latest") : idx - 51

        const firstDayOfCurrentMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1, 2);
        const firstDayOfNextMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1, 2);

        let month = "";
        if (firstDayOfCurrentMonth >= startDate && firstDayOfCurrentMonth <= endDate) {
            month = firstDayOfCurrentMonth.toLocaleString(undefined, { month: 'short' });
        } else if (firstDayOfNextMonth >= startDate && firstDayOfNextMonth <= endDate) {
            month = firstDayOfNextMonth.toLocaleString(undefined, { month: 'short' });
        }

        graphData[idx] = {
            ...graphData[idx],
            name,
            month,
            [resDataItem.name]: dataPoint,
            timeStamp
        };
        }
      );
    });
  }

  return (
    <div className="h-full">
      <div className="h-[46px] border-r border-b overflow-hidden flex justify-between items-center px-8 ">
        <div className="flex self-end gap-4">
          <Tab
            text={t("weekly_energy_use")}
            active={tab.weekly_energy}
            style="fixWhite"
            onClick={() =>
              setTab({ weekly_energy: true, category_share: false })
            }
          />
          <Tab
            text={t("energy_category_share")}
            active={tab.category_share}
            style="fixWhite"
            onClick={() =>
              setTab({ weekly_energy: false, category_share: true })
            }
          />
        </div>
        <div className="justify-items-end flex gap-4">
          <Button
            text={t("export_pdf")}
            icon="export_circle"
            enabled={false}
            onClick={() => undefined}
          />
        </div>
      </div>
      <div className="relative grow overflow-scroll no-scrollbar w-full h-full p-8 pb-16 shadow-inner">
        {Object.keys(res.data).length === 0 && tab.weekly_energy ? (
          <p className="font-yantramanavLight text-darkGray px-2">
            {t("no_analytics_to_show")}
          </p>
        ) : tab.weekly_energy ? (
          <div className="flex flex-col gap-8">
            <div>
              <div className="border-b border-mediumGray mb-1">
                <p className="font-yantramanavRegular text-primary leading-5 text-[16px]">
                  {t("weekly_energy_use")}
                </p>
              </div>
              <p className="font-yantramanavLight text-darkGray px-2 my-2">
                {t("graph_explanation_text")}
              </p>
              <div>
                <Barcharts
                  graphData={graphData}
                  rawData={res.data}
                  type="energy_past_52_weeks"
                  languageCode={languageCode}
                />
              </div>
            </div>
          </div>
        ) : null}

        {Object.keys(pieChart.data).length === 0 && tab.category_share ? (
          <p className="font-yantramanavLight text-darkGray px-2">
            {t("no_category_share_to_show")}
          </p>
        ) : tab.category_share ? (
          <div>
            <div className="border-b border-mediumGray mb-1">
              <p className="font-yantramanavRegular text-primary leading-5 text-[16px]">
                {t("energy_category_share")}
              </p>
            </div>
            <p className="font-yantramanavLight text-darkGray px-2 my-2">
              {t("graph_piechart_text")}
            </p>
            <div>
              <PieCharts
                graphData={pieChart.data.series}
                rawData={pieChart.data}
                type="energy_category_share_text"
                languageCode={languageCode}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
