import React, { useState, useEffect, useRef, useMemo, useCallback, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '../atoms/Icon';

/*
Notes:
I removed the zoom function. 
TODO: add the zoom function back in.  The issue is taht the map is also moving and its not smooth.
*/



// Memoized marker component
const Marker = memo(({ item, zoom, onMouseEnter, onMouseLeave, onClick }) => (
  <div
    className="absolute w-8 h-8 bg-primary rounded-full transform -translate-x-1/2 -translate-y-1/2 cursor-pointer flex justify-center items-center border-2 border-white"
    style={{
      left: `${item.location.pixel_location.x * zoom}px`,
      top: `${item.location.pixel_location.y * zoom}px`,
      transform: 'translate(-50%, -50%)',
    }}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={onClick}
  >
    <Icon size={20} color="white">
      {item.type.includes("arbitrary") ? "item_arbitrary_regular" : "item_" + item.type + "_regular"}
    </Icon>
  </div>
));


const MarketLocationMap = ({ marketData, itemList }) => {
  const navigate = useNavigate();
  const [selectedFloorPlan, setSelectedFloorPlan] = useState(Object.keys(marketData)[0]);
  const [currentFloorPlanUrl, setCurrentFloorPlanUrl] = useState(marketData[selectedFloorPlan]?.footprint_image?.url);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [zoom, setZoom] = useState(1);
  const containerRef = useRef(null);
  const canvasRef = useRef(null);


  // Memoize filtered items
  const itemsOnCurrentFloor = useMemo(() => 
    itemList.filter(item => item.location?.floor_plan === selectedFloorPlan),
    [itemList, selectedFloorPlan]
  );

  useEffect(() => {
    if (containerRef.current && marketData[selectedFloorPlan]?.footprint_image) {
      const containerWidth = containerRef.current.clientWidth;
      const imageWidth = marketData[selectedFloorPlan].footprint_image.width;
      const newZoom = containerWidth / imageWidth;
      setZoom(newZoom);
    }
    setCurrentFloorPlanUrl(marketData[selectedFloorPlan]?.footprint_image?.url);
  }, [selectedFloorPlan, marketData]);


  // Memoize event handlers
  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setStartY(e.pageY - containerRef.current.offsetTop);
    setScrollLeft(containerRef.current.scrollLeft);
    setScrollTop(containerRef.current.scrollTop);
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const y = e.pageY - containerRef.current.offsetTop;
    const walkX = (x - startX) * 2;
    const walkY = (y - startY) * 2;
    containerRef.current.scrollLeft = scrollLeft - walkX;
    containerRef.current.scrollTop = scrollTop - walkY;
  }, [isDragging, startX, startY, scrollLeft, scrollTop]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMarkerMouseEnter = useCallback((item) => {
    setHoveredItem(item);
  }, []);

  const handleMarkerMouseLeave = useCallback(() => {
    setHoveredItem(null);
  }, []);

  const handleMarkerClick = useCallback((itemId) => {
    navigate(`${itemId}`);
  }, [navigate]);

  // Add new useEffect for canvas rendering
  useEffect(() => {
    if (currentFloorPlanUrl && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const img = new Image();
      
      img.onload = () => {
        // Set canvas size to match the scaled image size
        canvas.width = img.width * zoom;
        canvas.height = img.height * zoom;
        
        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        
        // Draw the image at the new size
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
      
      img.src = currentFloorPlanUrl;
    }
  }, [currentFloorPlanUrl, zoom]);

  return (
    <div>
      {/* Floor plan selector */}
      <div className="mb-4">
        <select
          value={selectedFloorPlan}
          onChange={(e) => setSelectedFloorPlan(e.target.value)}
          className="block w-full p-2 border rounded-md"
        >
          {Object.keys(marketData).map((floorPlan) => (
            <option key={floorPlan} value={floorPlan}>
              {floorPlan}
            </option>
          ))}
        </select>
      </div>

      {/* Fixed size container with scrolling */}
      <div 
        ref={containerRef}
        className="border rounded-lg cursor-grab active:cursor-grabbing" 
        style={{ 
          width: '1000px', 
          height: '600px', 
          overflow: 'auto',
          position: 'relative'
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        <div 
          className="relative" 
          style={{ 
            overflow: 'visible',
            width: `${marketData[selectedFloorPlan]?.footprint_image?.width * zoom}px`,
            height: `${marketData[selectedFloorPlan]?.footprint_image?.height * zoom}px`
          }}
        >
          {currentFloorPlanUrl && (
            <canvas
              ref={canvasRef}
              className="max-w-none select-none"
              style={{ 
                display: 'block',
                width: '100%',
                height: '100%'
              }}
              draggable="false"
            />
          )}
          
          {/* Render markers for items on current floor */}
          {itemsOnCurrentFloor.map((item, index) => (
            <Marker
              key={`${item.id || index}-${item.location.pixel_location.x}-${item.location.pixel_location.y}`}
              item={item}
              zoom={zoom}
              onMouseEnter={() => handleMarkerMouseEnter(item)}
              onMouseLeave={handleMarkerMouseLeave}
              onClick={() => handleMarkerClick(item.item_id)}
            />
          ))}

          {hoveredItem && (
            <div 
              className="absolute bg-white border border-black rounded-md shadow-lg z-50"
              style={{
                width: `${300 / zoom}px`,
                height: `${200 / zoom}px`,
                left: hoveredItem.location.pixel_location.x * zoom > marketData[selectedFloorPlan]?.footprint_image.width * zoom - 300 
                ? `${hoveredItem.location.pixel_location.x * zoom - 300}px`  
                : `${hoveredItem.location.pixel_location.x * zoom + 10}px`,
                top: hoveredItem.location.pixel_location.y * zoom > marketData[selectedFloorPlan]?.footprint_image.height * zoom - 300 
                ? `${hoveredItem.location.pixel_location.y * zoom - 300}px`   
                : `${hoveredItem.location.pixel_location.y * zoom + 10}px`,
                transform: `scale(${zoom})`,
                transformOrigin: 'top left'
              }}
            >
              <div style={{ 
                width: '100%', 
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}>
                <div style={{ 
                  width: '100%', 
                  height: '75%',
                  overflow: 'hidden'
                }}>
                  <img 
                    src={hoveredItem?.main_images?.[0]?.sd} 
                    alt={""} 
                    style={{ 
                      width: '100%', 
                      height: '100%', 
                      objectFit: 'cover',
                      display: 'block'
                    }}
                  />
                </div>
                <p style={{ fontSize: `${16 / zoom}px` }}>
                  {hoveredItem.display_name || ''}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketLocationMap;






