import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAsyncStorage } from "../../utils/AsyncStorage";
import { useTranslation } from "react-i18next";
import Dropdown from "../atoms/Dropdown";
import Button from "./Button";
import Modal from "./Modal";
import Toast from "../atoms/Toast";

function Help({ isOpen, onClose }) {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    getAsyncStorage("user").then((res) => {
      setCurrentUser(res);
    });
  }, []);

  const handleOnBlurEmail = (text) => {
  };

  const enableButton = () => {
    return true;
  };


  return (
    <Modal isOpen={isOpen} modalTitle="help" onClose={onClose}>
      <div className="w-[356px] flex flex-col items-center gap-[52px]">
        <div className="w-full flex flex-col items-center gap-1">
          <p className="text-[24px] text-center text-primary font-yantramanavRegular leading-[31.13px]">
            {t("help_title")}
          </p>
        </div>

        <div className="w-[432px] flex flex-col gap-6 items-center bg-red-100">
          <div>
            <p> Select who to send to</p>
            <Dropdown/>
          </div>
          <div>
            <p>Write what to say</p>
          </div>
          <div className="w-[222px]">
            <Button
              fill
              text="send_email"
              icon="universal_email_regular"
              enabled={enableButton()}
              onClick={() => alert("e")}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Help;
