// #region Imports
import useMount from "../useMounts/Dashboard";
import ListView from "../components/organisms/ListView";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Footer from "../components/atoms/Footer";
import { useTranslation } from "react-i18next";
import SearchInput from "../components/atoms/SearchInput";
import Loadingsymbol from "../components/atoms/Loadingsymbol";
import birdImage from "../assets/bird.png";
import arrowRight from "../assets/arrow_right_filled.png";
import InviteUser from "../components/molecules/InviteUser";
import UpdateUser from "../components/molecules/UpdateUser";
import UserProfileImage from "../components/atoms/UserProfileImage";
import InteractiveIcon from "../components/atoms/InteractiveIcon";
import Tab from "../components/molecules/Tab";
import Map from "../components/organisms/Map/Map";
import InfoCard from "../components/atoms/InfoCard";
import ThumbnailCard from "../components/molecules/ThumbnailCard";
import { toast } from "react-toastify";
import Toast from "../components/atoms/Toast";
import { motion } from "framer-motion";
import PreviewModal from "../components/molecules/PreviewModal";
import Icon from "../components/atoms/Icon";
// #endregion

const Dashboard = () => {
  // #region Variables
  const { t } = useTranslation();
  const {
    simpleMarkets,
    lastVisitedMarkets,
    currentUser,
    allMissions,
    allIssues
  } = useMount();
  const [dashboardView, setDashboardView] = useState("home");
  const [searchString, setSearchString] = useState("");
  const [isBirdVisible, setBirdVisable] = useState(false);
  const [superHideBird, setSuperHideBird] = useState(true);
  const [advancedFilter, setAdvancedFilter] = useState({})
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState({
    invite: false,
    update: false,
    preview: false
  });
  const [showMarketArrows, setShowMarketArrows] = useState({
    left: false,
    right: true
  });

  const recentMarketListRef = useRef(null);
  const [previewData, setPreviewData] = useState({});
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const imageSlideBaseStyle = {
    zIndex: superHideBird ? "-100" : "0",
    opacity: superHideBird ? 0 : 1,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    transition: "bottom 0.3s",
    bottom: isBirdVisible ? `calc(30vh + 20vh)` : "30vh"
  };

  const categories = [
    { text: "home", icon: "other_home_bold" },
    { text: "market", icon: "other_market_bold" },
    // { text: "inventory", icon: "fieldagent" },
    { text: "service_work", icon: "logo_checklist_regular" },
    { text: "damage_report", icon: "logo_quicktell_regular" },
    { text: "analytics", icon: "other_barchart_bold" }
  ];
  // #endregion

  useEffect(() => {
    const updateInformation = localStorage.getItem("updateInformation");
    const shouldRefresh = sessionStorage.getItem(
      "refreshAfterUpdateInformation"
    );

    if (shouldRefresh && updateInformation) {
      toast.success(<Toast text={"your_information_has_been_updated"} />);
      sessionStorage.removeItem("refreshAfterUpdateInformation");
      localStorage.removeItem("updateInformation");
    }
  }, []);

  // #region Functions
  function refreshPage() {
    window.location.reload(false);
  }

  const mission_advanced_filter = () => {
    if (Object.keys(advancedFilter).length === 0) return allMissions;
    console.log(advancedFilter)
    let filtered_missions = [];
    return filtered_missions
  }

  const showBird = () => {
    setSuperHideBird(false);
    setBirdVisable(true);
    setTimeout(() => {
      setBirdVisable(false);
    }, "800");
    setTimeout(() => {
      setSuperHideBird(true);
    }, "1050");
  };

  const navigateToPage = (e, page) => {
    if (page === "market" && e?.market_id) {
      if (pathname.endsWith("old"))
        // Only for testing
        navigate("/singlePage/market/" + e.market_id); // Only for testing
      // Only for testing
      else navigate("markets/" + e.market_id, { state: { from: "/" } }); // Keep this
    }
    if (page === "missions" && e?.target?.target_id && e?.mission_id) {
      navigate("markets/" + e.target.target_id + "/missions/" + e.mission_id, {
        state: { from: "/" }
      });
    }
    if (page === "issues" && e?.market_id && e?._id) {
      navigate("markets/" + e.market_id + "/issues/" + e._id, {
        state: { from: "/" }
      });
    }
  };

  const expandInfo = (event, item) => {
    event.stopPropagation();
    setPreviewData(item);
    setIsModalOpen({ ...isModalOpen, preview: true });
  };

  const setListView = (button) => {
    if (button === "market") {
      return (
        <ListView
          key={"simpleMarkets"}
          data={simpleMarkets}
          columnOrder={[
            { key: "entrance_images", label: "entrance_images" },
            { key: "display_name", label: "display_name" },
            { key: "city", label: "city" },
            { key: "company", label: "company" }
          ]}
          onClick={(e) => navigateToPage(e, "market")}
          onClickExpandInfo={expandInfo}
          icon="universal_eyeopen_regular"
          searchString={searchString}
        />
      );
    } else if (button === "inventory") {
      return (
        <ListView
          key={"inventory"}
          data={[]}
          columnOrder={["image", "display_name", "market_id"]}
          onClick={(e) => navigateToPage(e, "inventory")}
          // onClickExpandInfo={expandInfo}
          searchString={searchString}
        />
      );
    } else if (button === "service_work") {
      return (
        <ListView
        key={"allMissions"}
        //   data={mission_advanced_filter(allMissions)}
          data={allMissions}
          columnOrder={[
            { key: "name", label: "name" },
            { key: "status", label: "status" }
          ]}
          onClick={(e) => navigateToPage(e, "missions")}
          searchString={searchString}
        />
      );
    } else if (button === "analytics") {
      return (
        <ListView
          key={"analytics"}
          data={[]}
          columnOrder={[{ key: "display_name", label: "display_name" }]}
          onClick={(e) => navigateToPage(e, "analytics")}
          // onClickExpandInfo={expandInfo}
          searchString={searchString}
        />
      );
    } else if (button === "damage_report") {
      return (
        <ListView
          key={"allIssues"}
          data={allIssues}
          columnOrder={[
            { key: "main_images", label: "main_images" },
            { key: "title", label: "title" },
            { key: "target.display_name", label: "display_name" }
          ]}
          onClick={(e) => navigateToPage(e, "issues")}
          searchString={searchString}
        />
      );
    }
  };
  // #endregion

  // #region Return HTML
  return (
    <motion.div
      key="dashboard"
      initial={{ translateX: "70%", opacity: 0 }}
      transition={{
        // Initial transition
        translateX: { duration: 0.3, ease: [0.33, 0.55, 0.41, 0.95] },
        opacity: { duration: 0.4, ease: "easeOut" }
      }}
      animate={{ translateX: 0, opacity: 1 }}
      exit={{
        translateX: "100%",
        opacity: 0,
        transition: { duration: 0.35, ease: "easeInOut" }
      }}
      className=" overflow-x-hidden"
    >
      <div className="h-screen flex flex-col bg-transparent ">
        {/* White boxes */}

        <div className="h-full w-full bg-transparent flex justify-center items-center overflow-hidden">
          <div className="w-[777px] h-[710px] flex flex-col justify-between gap-4 z-[1]">
            {/* Welcome User-box */}
            <div className="pt-[10px] pb-[10px] px-4 bg-whiteTint/75 backdrop-blur-[10px] rounded flex flex-row justify-between shadow-[rgba(0,0,0,0.15)_0px_4px_22px_0px]">
              <div className="flex flex-row gap-3">
                <UserProfileImage
                  firstName={currentUser?.first_name ?? ""}
                  lastName={currentUser?.last_name ?? ""}
                  pathImage={
                    (currentUser.profile_images ?? []).length > 0
                      ? currentUser.profile_images[0].hd
                      : null
                  }
                  size="small"
                />
                <div className="flex flex-col justify-center">
                  <p
                    className="text-[18px] leading-6 font-yantramanavRegular text-black"
                    onClick={() => showBird()}
                  >
                    {t("welcome")} {currentUser?.first_name ?? ""}{" "}
                    {currentUser?.last_name ?? ""}
                  </p>
                  <p className="text-[14px] leading-4 font-yantramanavLight text-darkGray">
                    {currentUser?.client ?? ""}
                  </p>
                </div>
              </div>

              <div className="flex flex-row gap-2 items-center">
                <InteractiveIcon
                  icon="universal_settings_regular"
                  color="black"
                  size="small"
                  onClick={() => setIsModalOpen({ update: true })}
                />
                <InteractiveIcon
                  icon="other_add_user_regular"
                  color="black"
                  size="small"
                  onClick={() => setIsModalOpen({ invite: true })}
                />
                <InteractiveIcon
                  icon="universal_exit_regular"
                  color="black"
                  size="small"
                  onClick={() => {
                    localStorage.clear();
                    toast.info(<Toast text="you_logged_out_successfully" />);
                    navigate("/login");
                  }}
                />
              </div>
            </div>

            {/* What to find-box */}
            <div className="h-[631px] w-full">
              <div className="flex flex-row items-end h-[34px]">
                {categories.map((el) => {
                  if (["analytics"].includes(el.text))
                    return (
                      <div className="mr-2" key={el.text}>
                        <Tab
                          enabled={false}
                          text={t(el.text)}
                          style="dashboard"
                          id={el}
                          icon={el.icon}
                          onClick={() => {}}
                          active={dashboardView === el.text}
                        />
                      </div>
                    );
                  return (
                    <div
                      className="mr-2 rounded-t shadow-[rgba(0,0,0,0.1)_2px_-3px_10px_1px] "
                      key={el.text}
                    >
                      <Tab
                        text={t(el.text)}
                        style="dashboard"
                        id={el}
                        icon={el.icon}
                        onClick={() => {
                          if (dashboardView === el.text) {
                            setDashboardView("home");
                          } else {
                            setDashboardView(el.text);
                          }
                        }}
                        active={dashboardView === el.text}
                      />
                    </div>
                  );
                })}
              </div>

              <div className="h-[597px] rounded-b rounded-r bg-whiteTint/75 backdrop-blur-md  p-6">
                {dashboardView === "home" ? (
                  <div className="h-full w-full flex flex-row gap-5">
                    {/* Activity Feed */}
                    <div className="w-[224px] overflow-hidden flex flex-col gap-1">
                      <p className="text-[16px] leading-[20.75px] font-yantramanavRegular text-black ">
                        {t("activity_feed")}
                      </p>

                      <div className="h-full overflow-scroll no-scrollbar">
                        {simpleMarkets
                          .filter((market) => market.is_populated)
                          .sort(
                            (a, b) =>
                              new Date(b.created_at) - new Date(a.created_at)
                          )
                          .slice(0, 30)
                          .map((market, idx) => (
                            <div className="mb-2" key={idx}>
                              <InfoCard
                                title={market.display_name}
                                info={market.street}
                                image={
                                  market.entrance_images
                                    ? market.entrance_images[0].thumbnail
                                    : "icon"
                                }
                                icon={"other_market_bold"}
                                onClick={() => navigateToPage(market, "market")}
                              />
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="w-[481px] h-full flex flex-col gap-6 ">
                      {/* Recent markets */}
                      <div className="h-[147px] flex flex-col gap-1 ">
                        <p className="text-[16px] leading-[20.75px] font-yantramanavRegular text-black">
                          {t("recent_markets")}
                        </p>
                        <div className="h-full relative ">
                          {showMarketArrows.left ? (
                            <div
                              onClick={() => {
                                if (!showMarketArrows.right) {
                                  setShowMarketArrows((prev) => ({
                                    ...prev,
                                    right: true
                                  }));
                                }
                                recentMarketListRef.current.scrollLeft -= 150;
                                if (
                                  recentMarketListRef.current.scrollLeft === 0
                                )
                                  setShowMarketArrows((prev) => ({
                                    ...prev,
                                    left: false
                                  }));
                              }}
                              className="w-8 hover:bg-neutral-500/90 rounded-r-md hover:w-12 hover:cursor-pointer group transition-all h-full absolute left-0 top-0 z-10 flex justify-center items-center"
                            >
                              <img
                                className="w-6 h-6 shadow-lg rounded-full bg-gray-400 group-hover:opacity-100 select-none rotate-180"
                                src={arrowRight}
                              ></img>
                            </div>
                          ) : null}
                          <div
                            className="w-full h-[113px] overflow-x-scroll no-scrollbar scroll-smooth"
                            ref={recentMarketListRef}
                          >
                            <div className="w-full h-full flex flex-row gap-2">
                              {lastVisitedMarkets.length ? (
                                lastVisitedMarkets?.map((el, index) => {
                                  return (
                                    <div key={index}>
                                      <ThumbnailCard
                                        index={index}
                                        image={el.entrance_images}
                                        title={el.display_name}
                                        subtitle={el.display_name}
                                        onClick={() =>
                                          navigateToPage(el, "market")
                                        }
                                      />
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="flex justify-center mt-8">
                                  <Loadingsymbol />
                                </div>
                              )}
                            </div>
                          </div>
                          {lastVisitedMarkets.length > 0 &&
                          showMarketArrows.right ? (
                            <motion.div
                              onClick={() => {
                                if (!showMarketArrows.left) {
                                  setShowMarketArrows((prev) => ({
                                    ...prev,
                                    left: true
                                  }));
                                }
                                let rM = recentMarketListRef.current;
                                const maxScroll =
                                  rM.scrollWidth - rM.clientWidth;
                                rM.scrollLeft += 150;
                                if (
                                  recentMarketListRef.current.scrollLeft ===
                                  maxScroll
                                )
                                  setShowMarketArrows((prev) => ({
                                    ...prev,
                                    right: false
                                  }));
                              }}
                              className="w-8 hover:bg-neutral-500/90 rounded-l-md hover:w-12 hover:cursor-pointer group transition-all h-full absolute right-0 top-0 z-10 flex justify-center items-center"
                            >
                              <img
                                className="w-6 h-6 shadow-lg rounded-full bg-gray-400 group-hover:opacity-100 select-none"
                                src={arrowRight}
                              ></img>
                            </motion.div>
                          ) : null}
                        </div>
                      </div>

                      {/* Map with markers */}
                      <div className="w-full h-[374px] flex flex-col gap-1">
                        <p className="text-[16px] leading-[20.75px] font-yantramanavRegular text-black ">
                          {t("map_of_markets")}
                        </p>
                        <Map mapCoord={simpleMarkets} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="h-full w-full overflow-hidden border-b border-mediumGray ">
                    <div className="w-3/5 mb-3">
                      
                        <SearchInput
                            searchable
                            placeholder={t("search")}
                            onChange={setSearchString}
                        />
                      {/* {dashboardView === "service_work" && (
                        <div className="mt-2">
                          <div 
                            className="flex items-center gap-2 cursor-pointer" 
                            onClick={() => setShowAdvancedFilter(prev => !prev)}
                          >
                            <span className="text-sm font-medium">{t("advanced_filter")}</span>
                            <Icon size={16}>
                              {advancedFilter ? "universal_chevronup_regular" : "universal_chevrondown_regular"}
                            </Icon>
                          </div>
                          {showAdvancedFilter && (
                            <div className="mt-2 p-3 border rounded-md">
                              <div className="flex flex-col gap-2">
                                <p className="text-sm font-medium">{t("category")}</p>
                                <p>{JSON.stringify(advancedFilter)}</p>
                                <div className="flex flex-wrap gap-2">
                                  {["heating", "cooling", "ventilation"].map((category) => (
                                    <div
                                      key={category}
                                      className={`px-3 py-1 rounded-full cursor-pointer text-sm ${
                                        advancedFilter["category"]?.includes(category)
                                          ? "bg-blue-500 text-white"
                                          : "bg-gray-100 text-gray-700"
                                      }`}
                                      onClick={() => {
                                        setAdvancedFilter((prev) => {
                                            let a = prev
                                            if (!a["category"]) {
                                                a["category"] = [category]
                                            }
                                            else if (a["category"].includes(category)) {
                                                console.log("removing", category)
                                                a["category"] = a["category"].filter((item) => item !== category)
                                            } else {
                                                a["category"] = [...a["category"], category]
                                            }
                                            if (a["category"].length === 0) {
                                                delete a["category"]
                                            }
                                            return a
                                        });
                                      }}
                                    >
                                      {t(category)}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )} */}
                    </div>
                    <div className="shadow-[rgba(0,0,0,0.15)_0px_4px_22px_0px] h-full w-full overflow-scroll no-scrollbar border-mediumGray border bg-white">
                      {setListView(dashboardView)}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Listview */}
          </div>
          {/* Footer */}
          {/* <Footer /> */}
          <InviteUser
            isOpen={isModalOpen.invite}
            onClose={() => setIsModalOpen({ ...isModalOpen, invite: false })}
          />
          <UpdateUser
            isOpen={isModalOpen.update}
            onClose={() => setIsModalOpen({ ...isModalOpen, update: false })}
            onUpdated={refreshPage}
          />
          <PreviewModal
            isOpen={isModalOpen.preview}
            onClose={() => setIsModalOpen({ ...isModalOpen, preview: false })}
            data={previewData}
            goToMarket={(e) => navigateToPage(e, "market")}
          />
        </div>
        <img src={birdImage} alt="Description" style={imageSlideBaseStyle} />
      </div>
    </motion.div>
  );
};
// #endregion
export default Dashboard;
