import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { basicGet, fetchData } from "../../api/api";
import Loadingsymbol from "../../components/atoms/Loadingsymbol";
import MarketLocationMap from "../../components/molecules/MarketLocationMap";




export default function MarketFloorplanView({marketData, itemsData}) {
  const { t } = useTranslation();


  if (itemsData.isPending && !marketData.isPending) {
    return (
      <div className="h-full">
        <div className="flex h-[46px] border-r border-b px-8 w-full"></div>
        {/** Empty header */}
        <div className="flex flex-col items-center mt-8">
          <Loadingsymbol />
          <p className="text-body font-yantramanaRegular text-primary">
            {t("loading")}...
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="h-full">
      <p>MapView</p>
      <MarketLocationMap marketData={marketData.location_structure} itemList={itemsData.filter(item => item?.location?.pixel_location)} />

    </div>
  );
}
